import React from "react";
import { motion } from "framer-motion";

export const Home = () => {
  const textParts = [
    { text: "Indie ", color: "white" },
    { text: "Game ", color: "rgb(36, 233, 74)" },
    { text: "Studio", color: "white" },
  ];

  return (
    <div className="flex flex-col flex-1 z-10 gap-4">
      <div className="flex flex-1 gap-2 items-end text-white font-extrabold">
        <div className="text-5xl md:text-6xl xl:text-8xl uppercase">I made</div>
        <img src="/imab_logo_green.png" alt="Logo" className="h-10 md:h-12 xl:h-20" />
        <div className="text-5xl md:text-6xl xl:text-8xl uppercase">Bug</div>
      </div>
      <div className="flex flex-1 justify-end gap-2">
        {textParts.map((part, partIndex) => (
          <span
            key={partIndex}
            className={`text-2xl md:text-3xl uppercase font-extrabold`}
            style={{ color: part.color }}
          >
            {part.text.split("").map((char, charIndex) => (
              <motion.span
                key={charIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: (partIndex * 6 + charIndex) * 0.1,
                  duration: 0.05,
                }}
              >
                {char}
              </motion.span>
            ))}
          </span>
        ))}
      </div>
    </div>
  );
};
