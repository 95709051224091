import React from "react";
import { useLocation } from "react-router-dom";

type SocialsContent = {
  src: string;
  alt: string;
  className?: string;
  link: string;
};

export const socialsContent: Array<SocialsContent> = [
  {
    src: "/socials/threads.svg",
    alt: "Threads logo",
    link: "https://www.threads.net/@imadeabug",
  },
  {
    src: "/socials/instagram.svg",
    alt: "Instagram logo",
    link: "https://www.instagram.com/imadeabug/",
  },
  {
    src: "/socials/email.svg",
    alt: "email logo",
    link: "mailto:imadeabug@gmail.com",
  },
  {
    src: "/socials/facebook.svg",
    alt: "facebook logo",
    link: "https://www.facebook.com/profile.php?id=61563982405959",
  },
  {
    src: "/socials/youtube.svg",
    alt: "youtube logo",
    className: "text-white",
    link: "https://www.youtube.com/channel/UCmmwTrfyh2nUL5yy7t7b8mw",
  },
  {
    src: "/socials/twitter-x.svg",
    alt: "twitter logo",
    link: "https://x.com/imadeabug",
  },
  {
    src: "/socials/tik-tok.svg",
    alt: "tikTok logo",
    link: "https://www.tiktok.com/@imadeabug",
  },
  {
    src: "/socials/google-play.svg",
    alt: "googlePlay logo",
    link: "https://play.google.com/store/apps/details?id=com.imadeabug.tapfast",
  },
  {
    src: "/socials/apple-store.svg",
    alt: "appleStore logo",
    link: "https://apps.apple.com/us/app/tap-fast/id6502991462",
  },
  {
    src: "/socials/linkedin.svg",
    alt: "linkedin logo",
    link: "https://www.linkedin.com/company/imadeabug",
  },
];

export const Footer = () => {

  const location = useLocation()

  const isHomepageRoute = location.pathname === "/" ? true : false

  return (
    <div className="flex items-center justify-center z-10 w-full bg-black flex-col gap-4">
      {
        !isHomepageRoute &&
        <img src="/horizontal_imab_text.png" alt="Logo" className="mt-4" />
      }
      <div className={isHomepageRoute ? "flex gap-4 mt-4" : "flex gap-4"}>
        <Socials socials={socialsContent} />
      </div>
      <img
        src="/iefp_logo.png"
        alt="iefp-logo"
        className="cursor-pointer w-2/3 xl:w-1/3"
        onClick={() => window.open("/IMadeABug_IEFP_Cartaz.pdf", "_blank")}
      />
    </div>
  );
};

const Socials = ({ socials }: { socials: Array<SocialsContent> }) => {
  return (
    <>
      {socials.map((item: SocialsContent) => {
        return (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center cursor-pointer hover:scale-110 transition-all active:scale-95"
          >
            <Image src={item.src} alt={item.alt} className={item.className} />
          </a>
        );
      })}
    </>
  );
};

const Image = ({
  src,
  alt,
  className,
}: {
  src: string;
  alt: string;
  className?: string;
}) => {
  return <img src={src} alt={alt} className={`${className} w-6 h-auto`} />;
};
