import { motion } from "framer-motion";
import { DownloadFunctionality } from "../../components/downloadFunctionality";

const arrayHref = [
  {
    href: "https://play.google.com/store/apps/details?id=com.imadeabug.hopnflip",
    src: "/GooglePlayBadge.svg",
    alt: "GooglePlay",
  },
  {
    href: "https://apps.apple.com/us/app/ataxx-strategy-board-game/id6737763316",
    src: "/AppStoreBadge.svg",
    alt: "AppStore",
  },
];

export const AtaxxScreen = () => {
  return (
    <div className="flex flex-col md:flex-row flex-1 justify-center items-center">
      {/* Left - Phone  */}
      <div className="hidden md:flex flex-1 items-center justify-center w-2/6">
        <motion.img
          src="/ataxx_mobile.png"
          alt="ataxx_mobile"
          className="w-3/4 pt-16"
          whileHover={{
            scale: 1.15, // Slight zoom-in
            rotate: [0, -5, 5, -3, 3, 0], // Subtle rotation effect
            rotateY: 15, // 3D tilt effect
            rotateX: 5, // Slight forward tilt
            transition: { duration: 0.6, ease: "easeInOut" },
          }}
          whileTap={{
            scale: 0.95, // Slight press-down effect when clicked
            rotateY: 0, // Resets tilt
            rotateX: 0,
            transition: { duration: 0.2 },
          }}
        />
      </div>
      {/* Center */}
      <div className="flex flex-1 flex-col items-start justify-center gap-4 md:justify-evenly md:gap-0 w-1/2 md:w-1/3">
        <div className="flex flex-col items-center gap-4 xl:gap-12">
          <img src="/logo_Ataxx.png" alt="ataxx_logo" className="md:w-2/5" />
          <img
            src="/ataxx_playbutton.png"
            alt="ataxx_play"
            className="md:w-4/5"
          />
        </div>
        <div className="flex w-full justify-center">
          <DownloadFunctionality arrayHref={arrayHref} />
        </div>
      </div>
      {/* Right */}
      <div className="flex items-end justify-center gap-4 pb-16 md:flex-col md:pr-16 md:w-2/6 md:pb-0">
        <img src="/ataxx_1brain.png" alt="ataxx_brain" className="w-1/6" />
        <img src="/ataxx_2.png" alt="ataxx_2" className="w-1/6" />
        <img src="/ataxx_3daily.png" alt="ataxx_3daily" className="w-1/6" />
        <img src="/ataxx_4bot.png" alt="ataxx_4bot" className="w-1/6" />
      </div>
    </div>
  );
};
