import React from "react";
import { SwiperCarousel } from "../../components/swiperCarousel";
import { TapFastScreen } from "./TapFastScreen";
import { AtaxxScreen } from "./AtaxxScreen";
import { BlankItScreen } from "./BlankItScreen";
import { CasualSweeperScreen } from "./CasualSweeperScreen";

export const Games = ({ handleBackgroundChange }: any) => {
  const dataForSwiper = [
    { game: "ataxx", component: <AtaxxScreen /> },
    { game: "tapfast", component: <TapFastScreen /> },
    { game: "casualsweeper", component: <CasualSweeperScreen /> },
    { game: "blankIt", component: <BlankItScreen /> },
  ];

  return (
    <div className="flex z-10 flex-1 gap-4 sm:gap-4 md:gap-4 lg:gap-24 h-full w-full overflow-hidden">
      <SwiperCarousel
        data={dataForSwiper}
        onSlideChange={handleBackgroundChange}
      />
    </div>
  );
};
