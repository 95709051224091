import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home";
import { Navbar } from "./components/navbar";
import { Games } from "./pages/games";
import { AboutUs } from "./pages/about-us";
import { Footer } from "./pages/footer";

// lesgo.v1cardeira
function App() {
  const [backgroundImage, setBackgroundImage] = useState(
    "url('/Texture_Background.png')"
  );

  const handleBackgroundChange = (slideIndex?: number) => {
    switch (slideIndex) {
      case 0:
        setBackgroundImage(
          "url('/ataxx_background.png') center center / cover no-repeat"
        );
        break;
      case 1:
        setBackgroundImage(
          "url('/TapFastWallpaper.png') center center / cover no-repeat"
        );
        break;
      case 2:
        setBackgroundImage("#CFCFCF");
        break;
      case 3:
        setBackgroundImage("#FFF");
        break;
      default:
        setBackgroundImage(
          "url('/Texture_Background.png') center center / cover no-repeat"
        );
        break;
    }
  };

  return (
    <Router>
      <div className="relative w-screen h-screen">
        {/* Background Image */}
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            background: backgroundImage,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Navbar handleBackgroundChange={handleBackgroundChange} />

        {/* Main Content Area */}
        <div className="flex flex-col items-center h-full pt-20 z-10">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/games"
              element={
                <Games handleBackgroundChange={handleBackgroundChange} />
              }
            />
            <Route path="/about-us" element={<AboutUs />} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
